import { Injectable } from '@angular/core';

import { LocationDto } from '../dtos/location.dto';
import { Location } from '../models/location';

import { MapperFromDto } from './mappers';

/** Location mapper. */
@Injectable({
	providedIn: 'root',
})
export class LocationMapper implements MapperFromDto<LocationDto, Location> {
	/** @inheritdoc */
	public fromDto(dto: LocationDto): Location {
		return {
			id: dto.id,
			name: dto.name,
			phone: dto.phone,
			code: dto.loc_id,
			prefix: dto.prefix,
		};
	}
}
