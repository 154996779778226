import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, merge, Observable, of, shareReplay, Subject, switchMap, tap } from 'rxjs';

import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

import { Login } from '../models/login';
import { User } from '../models/user';

import { PasswordReset } from '../models/password-reset';

import { AuthApiService } from './auth-api.service';
import { UserApiService } from './user-api.service';

/**
 * Stateful service for storing/managing information about the current user.
 */
@Injectable({
	providedIn: 'root',
})
export class UserService {
	private readonly userApiService = inject(UserApiService);

	private readonly authService = inject(AuthApiService);

	/** Current user. `null` when a user is not logged in. */
	public readonly currentUser$: Observable<User | null>;

	/** Whether the current user is authorized. */
	public readonly isAuthorized$: Observable<boolean>;

	private refresh$ = new BehaviorSubject<void>(undefined);

	private logout$ = new Subject<null>();

	public constructor() {
		this.currentUser$ = this.initCurrentUserStream();
		this.isAuthorized$ = this.currentUser$.pipe(map(user => user != null));
	}

	/**
	 * Login a user with email and password.
	 * @param loginData Login data.
	 */
	public login(loginData: Login): Observable<void> {
		return this.authService.login(loginData);
	}

	/**
	 * Requests to reset the password.
	 * @param data Data for resetting the password.
	 * @returns Message for the user.
	 */
	public resetPassword(data: PasswordReset.Data): Observable<string> {
		return this.authService.resetPassword(data);
	}

	/**
	 * Set new password and confirm resetting.
	 * @param data Confirm password reset.
	 * @returns Success message.
	 */
	public confirmPasswordReset(data: PasswordReset.Confirmation): Observable<string> {
		return this.authService.confirmPasswordReset(data);
	}

	/** Logout the user. */
	public logout(): Observable<void> {
		return this.authService.logout().pipe(tap(() => this.logout$.next(null)));
	}

	private initCurrentUserStream(): Observable<User | null> {
		return this.refresh$.pipe(
			switchMap(() => merge(
				this.userApiService.getCurrentUser(),
				this.logout$,
			)),
			catchError((error: unknown) => {
				if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.Unauthorized) {
					return of(null);
				}

				throw error;
			}),
			shareReplay({ bufferSize: 1, refCount: true }),
		);
	}

	/** Refresh user information. */
	public refresh(): void {
		this.refresh$.next();
	}
}
